<template>
    <div class="rb-view dialog">
        <el-form :ref="form_name" :model="form_data" size="mini" :rules="form_rules">
            <el-form-item label="散场限制:" prop="single_limit" required>
                <el-input-number v-model="form_data.single_limit" :min="1" :max="5" :step="1"></el-input-number>
                <div class="rb-input-tip">同时可预约:最低限制1场,最大限制5场</div>
            </el-form-item>
        </el-form>
        <div style="text-align:right;">
            <el-button type="primary" @click="submitForm(form_name)" size="mini">提交编辑</el-button>
            <el-button @click="onCloseDialog" size="mini">关闭</el-button>
        </div>
    </div>
</template>

<script>
    import {changeSingleLimit} from "@/api/hado/user";

    export default {
        name: "user-single-limit-edit",
        props:{
            user:{
                type:Object,
                default:()=>{return {}}
            }
        },
        data(){
            return{
                form_name:'user_single_limit_edit',
                form_data:{single_limit:1},
                form_rules:{},
            }
        },
        created(){
            this.form_data.user_id = this.user.id
            this.form_data.single_limit = this.user.single_limit
        },
        methods:{
            onCloseDialog(){
                this.$emit("onTellDialogClose")
            },
            submitForm(formName){
                this.$refs[formName].validate((valid)=>{
                    if(valid){
                        changeSingleLimit(this.form_data).then(()=>{
                            this.$emit('onTellDialogClose')
                        })
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .rb-input-tip{
        color: #969799;
        line-height: 14px;
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 0;
    }
</style>
