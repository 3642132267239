<template>
    <div :class="['rb-view',userId?'dialog':'']">
        <div>
            <div>
                <el-form :ref="search_form_name" :model="search_option" size="mini" inline label-width="80px">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="类型:" prop="coupon_type">
                                <el-radio-group v-model="search_option.coupon_type" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="reward">满减券</el-radio-button>
                                    <el-radio-button label="exchange">兑换券</el-radio-button>
                                    <el-radio-button label="voucher">抵用券</el-radio-button>
                                    <el-radio-button label="most">通用券</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="状态:" prop="coupon_record_status">
                                <el-radio-group v-model="search_option.coupon_record_status" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="un_claimed">未领取</el-radio-button>
                                    <el-radio-button label="un_used">未使用</el-radio-button>
                                    <el-radio-button label="using">使用中</el-radio-button>
                                    <el-radio-button label="used">已使用</el-radio-button>
                                    <el-radio-button label="expired">已过期</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="记录时间:">
                                <el-radio-group v-model="search_option.use_time_range" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="today">今日</el-radio-button>
                                    <el-radio-button label="yesterday">昨天</el-radio-button>
                                    <el-radio-button label="7day">7天内</el-radio-button>
                                    <el-radio-button label="30day">30天内</el-radio-button>
                                    <el-radio-button label="this_month">今月</el-radio-button>
                                    <el-radio-button label="this_year">今年</el-radio-button>
                                </el-radio-group>
                                <span>
                                    <span style="margin-left:20px;margin-right:10px;">自定义:</span>
                                      <el-date-picker @change="handleSearchTap"
                                                      v-model="search_option.use_time_range"
                                                      type="daterange"
                                                      range-separator="至"
                                                      start-placeholder="开始日期"
                                                      end-placeholder="结束日期">
                                      </el-date-picker>
                                    </span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="ID:" prop="coupon_record_id"><el-input v-model="search_option.id" clearable></el-input></el-form-item>
                            <el-form-item label="订单号:" prop="order_id"><el-input v-model="search_option.order_id" clearable></el-input></el-form-item>
                            <el-form-item label="用户ID:" prop="user_id"><el-input :clearable="userId?false:true" :disabled="userId?true:false" v-model="search_option.user_id"></el-input></el-form-item>
                            <el-form-item label="微信昵称:" prop="wx_nick_name"><el-input v-model="search_option.nick_name" clearable></el-input></el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-button type="primary" icon="el-icon-search" size="mini" style="" @click="handleSearchTap">查询</el-button>
                            <el-button icon="el-icon-delete" size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div style="margin-top: 16px;">
                <el-table :data="page_list" size="mini" border style="width:100%;">
                    <el-table-column label="ID" prop="id" width="150" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="user_id" label="用户ID" width="150" show-overflow-tooltip>
                        <template #default="scope">
                            <span v-if="scope.row.user_id&&scope.row.user_id>0" >{{scope.row.user_id}}</span>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="头像" width="60">
                        <template #default="scope">
                            <el-avatar :src="scope.row.avatar" size="small"></el-avatar>
                        </template>
                    </el-table-column>
                    <el-table-column prop="nick_name" label="微信昵称" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="coupon_name" label="优惠券名称" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="coupon_typ" label="优惠券类型" show-overflow-tooltip width="88">
                        <template #default="scope">
                            <span :style="{color:params_map['coupon_type']?params_map['coupon_type'][scope.row.coupon_type].color:''}">{{params_map['coupon_type']?params_map['coupon_type'][scope.row.coupon_type].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="with_money" label="优惠金额" width="80" align="right" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="order_id" label="订单号" width="150" show-overflow-tooltip>
                        <template #default="scope">
                            <router-link v-if="scope.row.order_id && scope.row.order_id>0" target="_blank" :to="'/order/order-detail?id='+scope.row.order_id">{{scope.row.order_id}}</router-link>
                            <span v-else>无</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="code" label="领券码" show-overflow-tooltip>
                        <template #default="scope">
                            <el-button v-if="scope.row.code" type="text" @click="onSeeQrCodeTap(scope.row)">二维码</el-button>
                        </template>
                    </el-table-column>
<!--                    <el-table-column prop="coupon_record_status_name" label="状态"></el-table-column>-->
                    <el-table-column prop="coupon_record_status" label="状态" align="center" width="70" show-overflow-tooltip>
                        <template #default="scope">
                            <span :style="{color:(params_map['coupon_record_status']&&params_map['coupon_record_status'][scope.row.coupon_record_status])?params_map['coupon_record_status'][scope.row.coupon_record_status].color:''}">{{(params_map['coupon_record_status'] && params_map['coupon_record_status'][scope.row.coupon_record_status])?params_map['coupon_record_status'][scope.row.coupon_record_status].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="origin_name" label="来源" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="created_at" label="创建时间" :formatter="elTimeStampFormat" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="use_time" label="使用时间" :formatter="elTimeStampFormat" show-overflow-tooltip></el-table-column>
                </el-table>
                <div style="margin-top:20px;margin-bottom: 0px; text-align:right;">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page_option.page"
                            :page-sizes="[5,10, 20, 30, 50,100,200]"
                            :page-size="page_option.size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="page_option.total"
                    ></el-pagination>
                </div>
            </div>
        </div>


        <el-dialog v-model="dialogQrVisible" :title="dialogQrTitle" width="600px" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <div style="text-align:center;padding:20px;">
                <vue-qrcode :value="dialogQrUrl" :options="{ width: 300 }"></vue-qrcode>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import VueQrcode from '@chenfengyuan/vue-qrcode'
    import {getList} from "@/api/hado/coupon-record";
    import {getParams} from "@/api/hado/param";
    import {formatTimestamp} from "@/utils/time";
    const param_list = ['coupon_type','coupon_record_status']
    export default {
        name: "coupon-record-list",
        components:{VueQrcode},
        props:{
            userId:{
                type:String,
                default:''
            }
        },
        data(){
            return{
                loading:false,
                search_form_name:'coupon_record_search',
                page_option:{page:1,size:30,total:0},
                search_option:{coupon_type:'all',coupon_record_status:'all',use_time_range:'all'},
                page_list:[],
                params:{coupon_type:[]},
                params_map_pure:{},
                params_map:{coupon_type:{}},

                dialogFormTitle:'',
                dialogQrUrl:'',
                dialogQrVisible:false,

            }
        },
        created(){
            //如果是dialog状态 即有userId状态的 就给个初始变量
            this.search_option.user_id = this.userId
            this.initPage()
        },
        methods: {
            initPage(){
                this.initParams().then(()=>{
                    this.getPageList({page:1,size:this.page_option.size},Object.assign({},this.search_option))
                })
            },
            reloadPage() {
                this.getPageList(this.page_option, this.search_option)
            },
            initParams(){
                return new Promise((resolve,reject)=>{
                    getParams(param_list.join(','),'en').then(resp=>{
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_pure = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl=>{
                            console.log('pl:',pl)
                            let tmp_map = {}
                            let tmp_map_pure = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm=>{
                                // tmp_map[pm.key]=pm.value
                                tmp_map_pure[pm.key]=pm.value
                                tmp_map[pm.key]={key:pm.key,value:pm.value,color:pm.color}

                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value]=pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_pure[pl] = tmp_map_pure
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_pure = tmp_params_map_pure
                        this.params_map_reverse = tmp_params_map_reverse
                        // console.log('params_map:',this.params_map)
                        // console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err)=>{
                        if(err){
                            console.error(err)
                        }
                        reject(err)
                    })
                })
            },
            getPageList(pageOpt,searchOpt){
                this.loading = true;
                getList(pageOpt.page,pageOpt.size,searchOpt).then(resp=>{
                    this.loading=false;
                    this.page_list = resp.data.records;
                    this.page_option.page = pageOpt.page;
                    this.page_option.total = resp.data.total;
                    this.page_option.size = pageOpt.size;
                }).catch(()=>{
                    this.loading = false;
                })
            },
            handleSearchTap(){
                let searchOpt = Object.assign({},this.search_option)
                this.getPageList({page:1,size:this.page_option.size},searchOpt)
            },
            handleSearchClear(formName){
                this.$refs[formName].resetFields();
                this.initPage()
            },
            handleSizeChange(pageSize){
                this.getPageList({page:1,size:pageSize},this.search_option);
            },
            handleCurrentChange(pageIndex){
                this.getPageList({page:pageIndex,size:this.page_option.size},this.search_option)
            },
            elTimeStampFormat(row,column,val){
                return formatTimestamp(val)
            },
            onSeeQrCodeTap(row){
                this.dialogQrTitle='领券码:'+ row.code;
                // this.dialogQrUrl='http://www.banbansx.com/lyl/gift?code='+row.gift_code;
                this.dialogQrUrl=row.qr_code_url;
                console.log('this.dialogQrUrl:',this.dialogQrUrl)
                this.dialogQrVisible=true
            },
        }
    }
</script>

<style scoped>

</style>
