<template>
    <!--  用户联系方式编辑  -->
    <div class="rb-view dialog">
        <el-form :ref="form_name" :model="form_data" size="mini" :rules="form_rules">
            <el-form-item label="姓名:" prop="real_name" required>
                <el-input v-model="form_data.real_name"></el-input>
            </el-form-item>
            <el-form-item label="电话:" prop="telephone" required>
                <el-input v-model="form_data.telephone"></el-input>
            </el-form-item>
        </el-form>
        <div style="text-align:right;">
            <el-button type="primary" @click="submitForm(form_name)" size="mini">提交编辑</el-button>
            <el-button @click="onCloseDialog" size="mini">关闭</el-button>
        </div>
    </div>
</template>

<script>
    import {updateContact} from "@/api/hado/user";

    export default {
        name: "user-contact-edit",
        props:{
            user:{
                type:Object,
                default:()=>{return {}}
            }
        },
        data(){
            return{
                form_name:'user_contact_edit',
                form_data:{real_name:'',telephone:''},
                form_rules:{},
            }
        },
        created(){
            this.form_data.id = this.user.id
            this.form_data.real_name = this.user.real_name
            this.form_data.telephone = this.user.telephone
        },
        methods:{
            onCloseDialog(){
                this.$emit("onTellDialogClose")
            },
            submitForm(formName){
                this.$refs[formName].validate((valid)=>{
                    if(valid){
                        updateContact(this.form_data).then(()=>{
                            this.$emit('onTellDialogClose')
                        })
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
