<template>
    <div class="rb-view">
        <div>
            <div>
                <el-form :ref="search_form_name" :model="search_option" size="mini" inline >
                    <el-row>
                        <el-col :span="4">
                            <el-form-item label="阵营:" prop="camp">
                                <el-radio-group v-model="search_option.camp" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="red">红方</el-radio-button>
                                    <el-radio-button label="blue">蓝方</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="性别:" prop="gender">
                                <el-radio-group v-model="search_option.gender" @change="handleSearchTap">
                                    <el-radio-button label="all">全部</el-radio-button>
                                    <el-radio-button label="unknow">未知</el-radio-button>
                                    <el-radio-button label="male">男</el-radio-button>
                                    <el-radio-button label="female">女</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="16">
                            <el-form-item label="会员等级:" prop="level">
                                <el-radio-group v-model="search_option.level" @change="handleSearchTap">
                                    <el-radio-button label="all">所有</el-radio-button>
                                    <el-radio-button label="normal">普通</el-radio-button>
                                    <el-radio-button label="weekday">工作日</el-radio-button>
                                    <el-radio-button label="everyday">巅峰</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="4"><el-form-item label="ID:" prop="id"><el-input clearable size="mini" v-model="search_option.id"></el-input></el-form-item></el-col>
                        <el-col :span="4"><el-form-item label="OpenID:" prop="open_id"><el-input clearable v-model="search_option.open_id"></el-input></el-form-item></el-col>
                        <el-col :span="4"><el-form-item label="UnionID:" prop="union_id"><el-input clearable v-model="search_option.union_id"></el-input></el-form-item></el-col>
                        <el-col :span="4"><el-form-item label="昵称:" prop="nick_name"><el-input clearable v-model="search_option.nick_name"></el-input></el-form-item></el-col>
                        <el-col :span="4"><el-form-item label="真实姓名:" prop="real_name"><el-input clearable v-model="search_option.real_name"></el-input></el-form-item></el-col>
                        <el-col :span="4"><el-form-item label="电话:" prop="telephone"><el-input clearable v-model="search_option.telephone"></el-input></el-form-item></el-col>
<!--                        <el-col :span="4"><el-form-item label="地区:" prop="region"><el-input clearable v-model="search_option.region"></el-input></el-form-item></el-col>-->
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-button type="primary" icon="el-icon-search" size="mini" style="" @click="handleSearchTap">查询</el-button>
                            <el-button icon="el-icon-delete" size="mini" @click="handleSearchClear(search_form_name)">清空</el-button>
                        </el-col>
                        <el-col :span="12" style="text-align:right">
                            <div style="display:flex;align-items:center;justify-content: flex-end;height: 100%;">
<!--                                <div style="color:#C0C4CC;font-size:9px;margin-right:6px;">会员等级统计于 2022-01-06 16:58:23</div>-->
                                <div style="color:#C0C4CC;font-size:9px;margin-right:6px;">会员等级统计于 {{level_calc.last_refresh_at}}</div>
                                <el-button  v-if="level_calc.status === true" type="text" size="mini">
                                    <el-icon class="el-icon-loading"></el-icon>
                                    <span>刷新中</span>
                                </el-button>
                                <el-button  v-else type="text" size="mini" @click="onRefreshLevelTap">
                                    <span>刷新</span>
                                </el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-form>
            </div>

            <div style="margin-top: 16px;">
                <el-table :data="page_list" v-loading="loading" size="mini" border @sort-change="handleSortChange">
                    <el-table-column label="ID" prop="id" width="150" fixed="left"></el-table-column>
                    <el-table-column label="头像" prop="avatar" width="49">
                        <template #default="scope">
                            <el-avatar :src="scope.row.avatar" size="small"></el-avatar>
                        </template>
                    </el-table-column>
                    <el-table-column label="昵称" prop="nick_name" min-width="72" show-overflow-tooltip></el-table-column>
<!--                    <el-table-column label="真实姓名" prop="real_name" width="72" show-overflow-tooltip></el-table-column>-->
                    <el-table-column label="性别" prop="gender" width="50" align="center">
                        <template #default="scope">
                            <span :style="{color:params_map['gender']?params_map['gender'][scope.row.gender].color:''}">{{params_map['gender']?params_map['gender'][scope.row.gender].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="姓名" prop="real_name" min-width="100" show-overflow-tooltip>
                        <template #default="scope">
                            <span class="score-cell"> {{scope.row.real_name? scope.row.real_name:'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'}} <el-button class="score-btn" type="text" size="mini" @click="showContactEditDialog(scope.row)">修改</el-button> </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="电话" prop="telephone" min-width="130" show-overflow-tooltip>
                        <template #default="scope">
                            <span class="score-cell"> {{scope.row.telephone?scope.row.telephone:'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'}} <el-button class="score-btn" type="text" size="mini" @click="showContactEditDialog(scope.row)">修改</el-button> </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="阵营" prop="camp" width="50" align="center">
                        <template #default="scope">
                            <span :style="{color:params_map['camp']?params_map['camp'][scope.row.camp].color:''}">{{params_map['camp']?params_map['camp'][scope.row.camp].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="会员等级" prop="level" width="88" align="center" show-overflow-tooltip>
                        <template #default="scope">
                            <span :style="{color:params_map['user_level']?params_map['user_level'][scope.row.level].color:''}">{{params_map['user_level']?params_map['user_level'][scope.row.level].value:''}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="当前到期" prop="expire_at" width="136" show-overflow-tooltip sortable="custom" :formatter="elTimeStampFormat"></el-table-column>
                    <el-table-column label="会员到期" prop="full_expire_at" width="136" show-overflow-tooltip sortable="custom" :formatter="elTimeStampFormat"></el-table-column>
<!--                    <el-table-column label="剩余天数" prop="expire_day" width="100" align="right" show-overflow-tooltip sortable="custom"></el-table-column>-->
                    <el-table-column label="剩余天数" prop="full_expire_at" width="100" align="right" show-overflow-tooltip sortable="custom">
                        <template #default="scope">{{scope.row.expire_day}}</template>
                    </el-table-column>
                    <el-table-column label="充值次数" prop="recharge_count" width="100" align="right" show-overflow-tooltip sortable="custom">
                        <template #default="scope">
                            <el-button type="text" size="mini" @click="showOrderRechargeListDialog(scope.row)">{{scope.row.recharge_count}}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="预约次数" prop="play_count" width="100" align="right" show-overflow-tooltip sortable="custom">
                        <template #default="scope">
                            <el-button type="text" size="mini" @click="showOrderPlayListDialog(scope.row)">{{scope.row.play_count}}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="核销次数" prop="verify_record_count" width="100" align="right" show-overflow-tooltip sortable="custom">
                        <template #default="scope">
                            <el-button type="text" size="mini" @click="showVerifyRecordListDialog(scope.row)">{{scope.row.verify_record_count}}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="赛季积分" prop="current_score" width="100" align="right" show-overflow-tooltip sortable="custom">
                        <template #default="scope">
                            <span class="score-cell"> <el-button class="score-btn" type="text" size="mini" @click="showEditScoreDialog(scope.row)">修改</el-button> {{scope.row.current_score}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="散场限制" prop="single_limit" width="100" align="right" show-overflow-tooltip sortable="custom">
                        <template #default="scope">
                            <span class="score-cell"> <el-button class="score-btn" type="text" size="mini" @click="showEditSingleLimitDialog(scope.row)">修改</el-button> {{scope.row.single_limit}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="优惠券" prop="coupon_record_count" width="100" align="right" show-overflow-tooltip sortable="custom">
                        <template #default="scope">
                            <el-button type="text" size="mini" @click="showCouponRecordListDialog(scope.row)">{{scope.row.coupon_record_count}}</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="地区" show-overflow-tooltip>
                        <template #default="scope">
                            <span>{{scope.row.country}} {{scope.row.province}} {{scope.row.city}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="登录次数" prop="logins" width="100" align="right" show-overflow-tooltip sortable="custom"></el-table-column>
                    <el-table-column label="最近登录" prop="last_login_at"  width="136" :formatter="elTimeStampFormat" show-overflow-tooltip sortable="custom"></el-table-column>
                    <el-table-column label="IP地址" prop="ip_address" width="120"></el-table-column>
                    <el-table-column label="操作" fixed="right" width="150">
                        <template #default="scope">
                            <el-button type="text" plain size="mini" @click="onRowViewTap(scope.row)">详情</el-button>
                            <el-button type="text" size="mini" @click="onSendCouponTap(scope.row)">发送优惠券</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="margin-top:20px;margin-bottom: 0px; text-align:right;">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page_option.page"
                            :page-sizes="[5,10, 20, 30, 50,100,200]"
                            :page-size="page_option.size"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="page_option.total"
                    ></el-pagination>
                </div>
            </div>
        </div>


        <el-dialog v-model="dialog_coupon_select_list_visible" :title="dialog_coupon_select_list_title" @close="onTellSendCouponDialogClose" width="70%" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <coupon-select-list :user-id="dialog_coupon_select_user_id" mode="single" scene="send" @onTellDialogClose="onTellSendCouponDialogClose"></coupon-select-list>
        </el-dialog>

        <el-dialog v-model="dialog_order_recharge_list_visible" :title="dialog_order_recharge_list_title" @close="onTellOrderRechargeListDialogClose" width="70%" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <order-recharge-list :user-id="dialog_order_recharge_list_user_id"></order-recharge-list>
        </el-dialog>

        <el-dialog v-model="dialog_order_play_list_visible" :title="dialog_order_play_list_title" @close="onTellOrderPlayListDialogClose" width="70%" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <order-play-list :user-id="dialog_order_play_list_user_id"></order-play-list>
        </el-dialog>

        <el-dialog v-model="dialog_verify_record_list_visible" :title="dialog_verify_record_list_title" @close="onTellVerifyRecordListDialogClose" width="70%" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <verify-record-list :user-id="dialog_verify_record_list_user_id"></verify-record-list>
        </el-dialog>

        <el-dialog v-model="dialog_edit_score_visible" :title="dialog_edit_score_title" @close="hideEditScoreDialog" width="400px" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <user-score-edit v-if="dialog_edit_score_visible" :user="dialog_edit_score_user"  @onTellDialogClose="hideEditScoreDialog"></user-score-edit>
        </el-dialog>

        <el-dialog v-model="dialog_edit_single_limit_visible" :title="dialog_edit_single_limit_title" @close="hideEditSingleLimitDialog" width="400px" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <user-single-limit-edit v-if="dialog_edit_single_limit_visible" :user="dialog_edit_single_limit_user"  @onTellDialogClose="hideEditSingleLimitDialog"></user-single-limit-edit>
        </el-dialog>

        <el-dialog v-model="dialog_coupon_record_list_visible" :title="dialog_coupon_record_list_title" @close="hideCouponRecordListDialog" width="70%" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <coupon-record-list :user-id="dialog_coupon_record_list_user_id"></coupon-record-list>
        </el-dialog>

        <el-dialog v-model="dialog_contact_edit_visible" :title="dialog_contact_edit_title" @close="hideContactEditDialog" width="400px" :close-on-click-modal="false" :fullscreen="false" :destroy-on-close="true">
            <user-contact-edit v-if="dialog_contact_edit_visible" :user="dialog_contact_edit_user" @onTellDialogClose="hideContactEditDialog"></user-contact-edit>
        </el-dialog>
    </div>
</template>

<script>
    import {getList, getRefreshLevelStatus, refreshLevel} from "@/api/hado/user";
    import {getParams} from "@/api/hado/param";
    const param_list = ['user_level','gender','camp']
    import CouponSelectList from '@/views/b/market/coupon-select-list'
    import OrderRechargeList from '@/views/b/order/order-recharge-list'
    import OrderPlayList from '@/views/b/order/order-play-list'
    import VerifyRecordList from '@/views/b/order/verify-record-list'
    import UserScoreEdit from '@/views/b/user/user-score-edit'
    import UserSingleLimitEdit from '@/views/b/user/user-single-limit-edit'
    import CouponRecordList from '@/views/b/market/coupon-record-list'
    import UserContactEdit from '@/views/b/user/user-contact-edit'
    import {formatTimestamp} from "@/utils/time";
    import {mapGetters} from "vuex";
    export default {
        name: "user-vip-list",
        components:{CouponSelectList,OrderRechargeList,OrderPlayList,VerifyRecordList,UserScoreEdit,UserSingleLimitEdit,CouponRecordList,UserContactEdit},
        data(){
            return{
                loading:false,
                search_form_name:'user_search',
                page_option:{page:1,size:30,total:0},
                base_search_option:{is_check_vip:true},
                search_option:{camp:'all',gender:'all',level:'all'},
                page_list:[],
                params:{user_level:[],gender:[],camp:[]},
                params_map_pure:{},
                params_map:{user_level:{},gender:{},camp:{}},



                dialog_coupon_select_list_visible:false,
                dialog_coupon_select_list_title:'请选择要派发的优惠券',
                dialog_coupon_select_user_id:'',

                dialog_order_recharge_list_visible:false,
                dialog_order_recharge_list_title:'',
                dialog_order_recharge_list_user_id:'',

                dialog_order_play_list_visible:false,
                dialog_order_play_list_title:'',
                dialog_order_play_list_user_id:'',

                dialog_verify_record_list_visible:false,
                dialog_verify_record_list_title:'',
                dialog_verify_record_list_user_id:'',

                dialog_edit_score_visible:false,
                dialog_edit_score_title:'',
                dialog_edit_score_user:null,

                dialog_edit_single_limit_visible:false,
                dialog_edit_single_limit_title:'',
                dialog_edit_single_limit_user:null,

                dialog_coupon_record_list_visible:false,
                dialog_coupon_record_list_title:'',
                dialog_coupon_record_list_user_id:'',

                dialog_contact_edit_visible:false,
                dialog_contact_edit_title:'',
                dialog_contact_edit_user:null,

                is_calc:false,//是否计算中
                level_calc:{status:false,last_refresh_at:' - '},
            }
        },
        created(){
            this.initPage()
        },
        computed:{
            ...mapGetters([
                'profile'
            ])
        },
        methods:{
            initPage(){
                this.initParams().then(()=>{
                    this.getPageList({page:1,size:this.page_option.size},Object.assign({},this.search_option))
                    this.initRefreshLevelStatus()
                })
            },
            reloadPage(){
                this.getPageList(this.page_option, this.search_option)
            },
            initParams(){
                return new Promise((resolve,reject)=>{
                    getParams(param_list.join(','),'en').then(resp=>{
                        this.params = resp.data;

                        let tmp_params_map = {}
                        let tmp_params_map_pure = {}
                        let tmp_params_map_reverse = {}

                        //这里再这里一下每组参数做成kv方便显示
                        let p_keys = Object.keys(this.params)
                        p_keys.forEach(pl=>{
                            console.log('pl:',pl)
                            let tmp_map = {}
                            let tmp_map_pure = {}
                            let tmp_map_reverse = {}
                            this.params[pl].forEach(pm=>{
                                // tmp_map[pm.key]=pm.value
                                tmp_map_pure[pm.key]=pm.value
                                tmp_map[pm.key]={key:pm.key,value:pm.value,color:pm.color}

                                //再调转处理一下 导入Excel参数解析用
                                tmp_map_reverse[pm.value]=pm.key
                            })
                            tmp_params_map[pl] = tmp_map
                            tmp_params_map_pure[pl] = tmp_map_pure
                            tmp_params_map_reverse[pl] = tmp_map_reverse
                        })

                        this.params_map = tmp_params_map
                        this.params_map_pure = tmp_params_map_pure
                        this.params_map_reverse = tmp_params_map_reverse
                        // console.log('params_map:',this.params_map)
                        // console.log('params_map_reverse:',this.params_map_reverse)
                        resolve(resp)
                    }).catch((err)=>{
                        if(err){
                            console.error(err)
                        }
                        reject(err)
                    })
                })
            },
            getPageList(pageOpt,searchOpt){
                this.loading = true;
                let sopt = Object.assign({},this.base_search_option,searchOpt)
                getList(pageOpt.page,pageOpt.size,sopt).then(resp=>{
                    this.loading=false;
                    this.page_list = resp.data.records;
                    this.page_option.page = pageOpt.page;
                    this.page_option.total = resp.data.total;
                    this.page_option.size = pageOpt.size;

                    this.page_list.forEach(row=>{
                        row.expire_day = parseInt((row.full_expire_at - new Date().getTime())/(1000*3600*24))
                        if(row.expire_day <0){
                            row.expire_day = '-'
                        }
                    })


                }).catch(()=>{
                    this.loading = false;
                })
            },
            handleSearchTap(){
                let searchOpt = Object.assign({},this.search_option)
                this.getPageList({page:1,size:this.page_option.size},searchOpt)
            },
            handleSearchClear(formName){
                this.$refs[formName].resetFields();
                this.initPage()
            },
            handleSizeChange(pageSize){
                this.getPageList({page:1,size:pageSize},this.search_option);
            },
            handleCurrentChange(pageIndex){
                this.getPageList({page:pageIndex,size:this.page_option.size},this.search_option)
            },
            onRowViewTap(row){
                console.log('onRowViewTap:',row)
                this.$router.push({path:'/b/user-detail',query:{id:row.id}})
            },
            onSendCouponTap(row){
                this.dialog_coupon_select_user_id = row.id
                this.dialog_coupon_select_list_visible=true
            },
            onTellSendCouponDialogClose(){
                this.dialog_coupon_select_list_visible=false
                this.dialog_coupon_select_user_id=''
            },
            elTimeStampFormat(row,column,val){
                return formatTimestamp(val)
            },
            handleSortChange(e){
                this.search_option.sort_field = e.prop;
                let sort_dct = {ascending:"asc",descending:"desc",}
                this.search_option.sort = sort_dct[e.order]
                this.initPage()
            },
            showOrderRechargeListDialog(row){
                this.dialog_order_recharge_list_title=`用户:${row.nick_name} (ID:${row.id})`
                this.dialog_order_recharge_list_user_id=row.id
                this.dialog_order_recharge_list_visible = true
            },
            onTellOrderRechargeListDialogClose(){
                this.dialog_order_recharge_list_visible = false
                this.dialog_order_recharge_list_title=''
                this.dialog_order_recharge_list_user_id = ''
            },
            showOrderPlayListDialog(row){
                this.dialog_order_play_list_title=`用户:${row.nick_name} (ID:${row.id})`
                this.dialog_order_play_list_user_id = row.id
                this.dialog_order_play_list_visible = true
            },
            showVerifyRecordListDialog(row){
                this.dialog_verify_record_list_title = `用户:${row.nick_name} (ID:${row.id})`
                this.dialog_verify_record_list_user_id = row.id
                this.dialog_verify_record_list_visible = true
            },
            onTellOrderPlayListDialogClose(){
                this.dialog_order_play_list_title=''
                this.dialog_order_play_list_user_id = ''
                this.dialog_order_play_list_visible = false
            },
            onTellVerifyRecordListDialogClose(){
                this.dialog_verify_record_list_title = ''
                this.dialog_verify_record_list_user_id = ''
                this.dialog_verify_record_list_visible = false
            },
            showEditScoreDialog(row){
                this.dialog_edit_score_user = row
                this.dialog_edit_score_title = `用户:${row.nick_name} (ID:${row.id})`
                this.dialog_edit_score_visible = true
            },
            hideEditScoreDialog(){
                this.dialog_edit_score_visible = false
                this.dialog_edit_score_title = ''
                this.dialog_edit_score_user = null
                this.reloadPage()
            },
            showEditSingleLimitDialog(row){
                this.dialog_edit_single_limit_user = row
                this.dialog_edit_single_limit_title = `用户:${row.nick_name} (ID:${row.id})`
                this.dialog_edit_single_limit_visible = true
            },
            hideEditSingleLimitDialog(){
                this.dialog_edit_single_limit_visible = false
                this.dialog_edit_single_limit_title = ''
                this.dialog_edit_single_limit_user = null
                this.reloadPage()
            },
            showCouponRecordListDialog(row){
                this.dialog_coupon_record_list_title = `用户:${row.nick_name} (ID:${row.id})`
                this.dialog_coupon_record_list_user_id = row.id
                this.dialog_coupon_record_list_visible = true
            },
            hideCouponRecordListDialog(){
                this.dialog_coupon_record_list_visible = false
                this.dialog_coupon_record_list_title = ''
                this.dialog_coupon_record_list_user_id = ''
                this.reloadPage()
            },
            showContactEditDialog(row){
                this.dialog_contact_edit_title = `用户:${row.nick_name} (ID:${row.id})`
                this.dialog_contact_edit_user = row
                this.dialog_contact_edit_visible = true
            },
            hideContactEditDialog(){
                this.dialog_contact_edit_visible = false
                this.dialog_contact_edit_title = ''
                this.dialog_contact_edit_user = null
                this.reloadPage()
            },
            //刷新用户等级统计
            onRefreshLevelTap(){
                // this.is_calc = true
                this.level_calc.status = true
                refreshLevel().then(()=>{
                    this.spyRefreshLevelStatus()
                })
            },
            initRefreshLevelStatus(){
                getRefreshLevelStatus().then(resp=>{
                    let d = resp.data
                    this.level_calc.status = d.status
                    this.level_calc.last_refresh_at = formatTimestamp(d.last_refresh_at)
                })
            },
            spyRefreshLevelStatus(){
                setTimeout(()=>{
                    getRefreshLevelStatus().then(resp=>{
                        let d = resp.data
                        this.level_calc.status = d.status
                        this.level_calc.last_refresh_at = formatTimestamp(d.last_refresh_at)
                        if(d && d.status === true){ //true 是刷新中 false 是非进行中
                            this.spyRefreshLevelStatus()
                        }
                    })
                },1000)
            }
        }
    }
</script>

<style scoped>
    .score-cell{}
    .score-cell:hover .score-btn{display:initial}
    .score-btn{display:none;}
</style>
