<template>
    <!-- 积分修改编辑框 -->
    <div class="rb-view dialog">
        <el-form :ref="form_name" :model="form_data" size="mini" :rules="form_rules">
            <el-form-item label="增加积分:" prop="score_add" required >
                <el-input-number v-model="form_data.score_add" :step="5" :min="-2000" :max="2000"></el-input-number>
                <span style="margin-left:10px;">当前积分:<span style="color:#409EFF;font-weight:bold;">{{user.current_score}}</span></span>
            </el-form-item>
        </el-form>
        <div style="text-align:right;">
            <el-button type="primary" @click="submitForm(form_name)" size="mini">提交编辑</el-button>
            <el-button @click="onCloseDialog" size="mini">关闭</el-button>
        </div>
    </div>
</template>

<script>
    import {addScore} from "@/api/hado/user";

    export default {
        name: "user-score-edit",
        props:{
          user:{
              type:Object,
              default:()=>{return {}}
          }
        },
        data(){
            return{
                form_name:'user_score_edit',
                form_data:{score_add:0},
                form_rules:{},
            }
        },
        created(){
            // console.log('this.user:',this.user)
            //检测传参
            this.form_data.user_id = this.user.id

        },
        methods:{
            onCloseDialog(){
                this.$emit("onTellDialogClose")
            },
            submitForm(formName){
                this.$refs[formName].validate((valid)=>{
                    if(valid){
                        addScore(this.form_data).then(()=>{
                            this.$emit('onTellDialogClose')
                        })
                    }
                })
            },
        }


    }
</script>

<style scoped>

</style>
